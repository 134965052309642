import React from 'react'
import NotFound from '../layouts/404'


import { graphql } from 'gatsby'
import { I18n } from 'react-i18next';
import { withI18next } from 'gatsby-plugin-i18next';

import { getContentTranslation } from '../helpers'

import notFound from '../content/404'


const NotFoundPage = () => <I18n>
{(t,{i18n}) => {
  const {language} = i18n
  return <NotFound {...getContentTranslation(notFound, language)} />
}}
</I18n>

export default withI18next()(NotFoundPage)

export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;