import React from 'react'
import PropTypes from 'prop-types'
import Layout from './MainLayout'
import { Link } from 'gatsby-plugin-i18next'

const NotFoundPage = ({title, message, link, buttonLabel}) => (
  <Layout>
        <section id="one">
            <div className="inner" style={{textAlign: 'center'}}>
                <h1>{title}</h1>
                <p>{message}</p>
                <Link to={link} className="button">{buttonLabel}</Link> 
            </div>
        </section>
  </Layout>
)

NotFoundPage.defaultProps = {
  link: '/',
}

NotFoundPage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
}

export default NotFoundPage
