
const notFoundEnglish =  {
    title: '404 Not found',
    message: 'You just hit a route that doesn t exist... .',
    buttonLabel: 'Continue',
}

  const notFoundSlovak =  {
    title: '404 - Nenájdené',
    message: 'Práve ste navštívili stránku ktorá neexistuje ...',
    buttonLabel: 'Pokračovať',
  }

  const notFoundMagyar = {
    title: '404 NOT FOUND',
    message: 'You just hit a route that doesn&#39;t exist... the sadness.',
    buttonLabel: 'Continue',
  }

  const notFound = {
      'sk': notFoundSlovak,
      'en': notFoundEnglish,
      'hu': notFoundMagyar
  }

  export default notFound